import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { isEqual } from 'lodash-es';
import { DateTime } from 'luxon';
import { BaseChartDirective } from 'ng2-charts';
import { interval, startWith, Subscription, takeUntil } from 'rxjs';
import { STATISTICS_EXPOSURES_POLLING_INTERVAL } from 'src/app/constants';
import { unsubscribeMixin } from 'src/app/core/unsubscribe';
import { StatisticsApi, StatisticsPerDay } from 'src/_api';
import { StatisticsFilterValues } from '../statistics-filter.component';
import { ChartWeekday, StatisticsInitData, StatisticsService } from '../statistics.service';

@Component({
  selector: 'flow-views-bar-chart',
  templateUrl: './views-bar-chart.component.html',
  styleUrls: ['./views-bar-chart.component.scss']
})
export class ViewsBarChartComponent extends unsubscribeMixin() implements OnInit, OnChanges {

  @Input() startDT: DateTime;
  @Input() endDT: DateTime;
  @Input() filter: StatisticsFilterValues;
  @Input() initData: StatisticsInitData;
  @Input() columnType: 'day' | 'date';

  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

  panelOpenState = false;
  barChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      x: {},
      y: {}
    },
    plugins: {
      legend: {
        display: true,
      },
      datalabels: {
        anchor: 'end',
        align: 'end'
      }
    }
  };
  barChartType: ChartType = 'bar';
  barChartPlugins = [
    DataLabelsPlugin
  ];

  barChartData: ChartData<'bar'> = null;
  weekdays: ChartWeekday[];
  statsRequest: Subscription;
  statsPerDay: StatisticsPerDay[];
  groupByFC = new FormControl(GroupByData.Total);
  hideDefaultAdFC = new FormControl(true);
  GroupByData = GroupByData;

  constructor(
    private statisticsApi: StatisticsApi,
    private statisticsService: StatisticsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.groupByFC.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(this.onGroupByChange);
    this.hideDefaultAdFC.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(this.onHideDefaultAdChange);
    this.weekdays = this.statisticsService.buildPeriod(this.startDT, this.endDT, this.columnType);
    interval(STATISTICS_EXPOSURES_POLLING_INTERVAL)
      .pipe(startWith(0), takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.getStats();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filter?.currentValue) {
      this.getStats(changes.filter.currentValue);
    }
  }

  override ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.statsRequest?.unsubscribe();
  }

  private getStats(filter?: StatisticsFilterValues): void {
    this.statsRequest?.unsubscribe();
    this.statsRequest = this.statisticsApi.filtered({
      body: {
        startTime: this.startDT.toISO(),
        endTime: this.endDT.toISO(),
        campaignIds: filter?.campaigns?.map(c => c.id),
        screensIds: filter?.screens?.map(s => s.id),
        targetGroupIds: filter?.targetGroups?.map(tg => tg.id)
      }
    }).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((stats) => {
        if (isEqual(stats.perDay, this.statsPerDay)) {
          console.log('equal data');
          return;
        }
        this.statsPerDay = stats.perDay;
        console.log('chart data up to date', this.statsPerDay);
        this.barChartData = this.mapChartData(this.weekdays, stats.perDay, this.groupByFC.value, this.hideDefaultAdFC.value);
        this.chart.update();
      });
  }

  private onGroupByChange = (value: GroupByData): void => {
    this.barChartData = this.mapChartData(this.weekdays, this.statsPerDay, value, this.hideDefaultAdFC.value);
    this.chart.update();
  };

  private onHideDefaultAdChange = (value: boolean): void => {
    this.barChartData = this.mapChartData(this.weekdays, this.statsPerDay, this.groupByFC.value, value);
    this.chart.update();
  };

  private mapChartData(days: ChartWeekday[], perDay: StatisticsPerDay[], groupBy: GroupByData, hideDefaultAd: boolean): ChartData<'bar'> {
    if (hideDefaultAd) {
      perDay = perDay?.map(pd => {
        return {
          adSlots: pd.adSlots?.filter(slot => slot.campaignId > 0),
          date: pd.date
        };
      })
    }
    if (groupBy === GroupByData.Campaign) {
      return this.chartDataByCampaign(days, perDay);
    } else if (groupBy === GroupByData.Screen) {
      return this.chartDataByScreen(days, perDay);
    } else if (groupBy === GroupByData.TargetGroup) {
      return this.chartDataByTargetGroup(days, perDay);
    } else {
      return {
        labels: days.map(day => day.label),
        datasets: [
          {
            label: 'Antal exponeringar',
            data: days.map(day => {
              const adSlots = perDay.find(d => d.date === day.utcString)?.adSlots || [];
              return adSlots.length || null;
            })
          }
        ]
      };
    }
  }

  private chartDataByCampaign(days: ChartWeekday[], perDay: StatisticsPerDay[]): ChartData<'bar'> {
    const groupedByCampaign = this.statisticsService.groupDataByCampaign(perDay);
    const barChartData: ChartData<'bar'> = {
      labels: days.map(day => day.label),
      datasets: groupedByCampaign.map(group => {
        const campaign = this.initData?.campaigns?.find(c => c.id === group.campaignId);
        return {
          label: campaign?.name || (group.campaignId === 0 ? 'Standard' : ('ID: ' + group.campaignId)),
          data: days.map(day => {
            const count = group.perDay.find(pd => pd.date === day.utcString)?.count || null;
            return count;
          })
        }
      })
    };
    console.log(barChartData);
    return barChartData;
  }

  private chartDataByScreen(days: ChartWeekday[], perDay: StatisticsPerDay[]): ChartData<'bar'> {
    const groupedByScreen = this.statisticsService.groupDataByScreen(perDay);
    const barChartData: ChartData<'bar'> = {
      labels: days.map(day => day.label),
      datasets: groupedByScreen.map(group => {
        const screen = this.initData?.screens?.find(s => s.id === group.screenId);
        return {
          label: screen?.humanUniqueIdentifier || ('ID: ' + group.screenId),
          data: days.map(day => {
            const count = group.perDay.find(pd => pd.date === day.utcString)?.count || null;
            return count;
          })
        }
      })
    };
    console.log(barChartData);
    return barChartData;
  }

  private chartDataByTargetGroup(days: ChartWeekday[], perDay: StatisticsPerDay[]): ChartData<'bar'> {
    const groupedByTargetGroup = this.statisticsService.groupDataByTargetGroup(perDay);
    const barChartData: ChartData<'bar'> = {
      labels: days.map(day => day.label),
      datasets: groupedByTargetGroup.map(group => {
        const targetGroup = this.initData?.targetGroups?.find(tg => tg.id === group.targetGroupId);
        return {
          label: targetGroup?.name || (group.targetGroupId === 0 ? 'Standard' : ('ID: ' + group.targetGroupId)),
          data: days.map(day => {
            const count = group.perDay.find(pd => pd.date === day.utcString)?.count || null;
            return count;
          })
        }
      })
    };
    console.log(barChartData);
    return barChartData;
  }
}



enum GroupByData {
  Total,
  Campaign,
  Screen,
  TargetGroup
}
