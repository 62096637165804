<div class="filter-container my-5 mat-elevation-z1">
  <mat-accordion [displayMode]="'flat'"
                 [formGroup]="form"
                 class="accordion-filter">
    <mat-expansion-panel [expanded]="panelOpenState"
                         (opened)="panelOpenState = true"
                         (closed)="panelOpenState = false">
      <mat-expansion-panel-header [collapsedHeight]="'60px'">
        <mat-panel-title>
          <h2 *ngIf="!filterSummary?.length"
              class="accordion-filter-title mt-5 text-center">
            Filtrera...
          </h2>
          <mat-chip-list *ngIf="filterSummary?.length"
                         #chipList>
            <mat-chip *ngFor="let filterObj of filterSummary"
                      selected
                      color="primary"
                      (removed)="removeSummaryObject(filterObj)">
              {{filterObj.label}}
              <button matChipRemove>
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip>
          </mat-chip-list>
        </mat-panel-title>
        <mat-panel-description>
          <mat-icon color="accent">filter_alt</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <mat-form-field class="mr-3"
                      *ngIf="initData?.campaigns">
        <mat-label>Kampanjer</mat-label>
        <mat-select formControlName="campaigns"
                    multiple>
          <mat-option *ngFor="let campaign of initData?.campaigns"
                      [value]="campaign">
            {{campaign.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="mr-3">
        <mat-label>Skärmar</mat-label>
        <mat-select formControlName="screens"
                    multiple>
          <mat-option *ngFor="let screen of initData?.screens"
                      [value]="screen">
            {{screen.humanUniqueIdentifier}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="mr-3"
                      *ngIf="initData?.targetGroups">
        <mat-label>Målgrupper</mat-label>
        <mat-select formControlName="targetGroups"
                    multiple>
          <mat-option *ngFor="let targetGroup of initData?.targetGroups"
                      [value]="targetGroup">
            {{targetGroup.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

    </mat-expansion-panel>
  </mat-accordion>
</div>
