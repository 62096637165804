import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs';
import { unsubscribeMixin } from 'src/app/core/unsubscribe';
import { Campaign, Screen, TargetGroup } from 'src/_api';
import { StatisticsInitData } from './statistics.service';

@Component({
  selector: 'flow-statistics-filter',
  templateUrl: './statistics-filter.component.html',
  styleUrls: ['./statistics-filter.component.scss']
})
export class StatisticsFilterComponent extends unsubscribeMixin() implements OnInit {

  @Input() initData: StatisticsInitData;
  @Output() filterChange = new EventEmitter<StatisticsFilterValues>();

  panelOpenState = false;
  form: FormGroup<StatisticsFilterFormGroup>;
  filterSummary: FilterSummaryObject[] = [];

  constructor(
    private formBuilder: FormBuilder
  ) {
    super();
    this.initForm();
  }

  ngOnInit(): void { }

  removeSummaryObject(obj: FilterSummaryObject): void {
    const typeValues = this.form.get(obj.filterType).value;
    typeValues.splice(typeValues.findIndex(value => value.id === obj.id), 1);
    this.form.get(obj.filterType).setValue(typeValues);
  }

  private createSummaryObjects(filter: StatisticsFilterValues): void {
    this.filterSummary = [];
    filter?.campaigns?.forEach(c => {
      this.filterSummary.push({ id: c.id, label: c.name, filterType: 'campaigns' });
    });
    filter?.screens?.forEach(s => {
      this.filterSummary.push({ id: s.id, label: s.humanUniqueIdentifier, filterType: 'screens' });
    })
    filter?.targetGroups?.forEach(tg => {
      this.filterSummary.push({ id: tg.id, label: tg.name, filterType: 'targetGroups' });
    });
  }

  private onFilterChange = (filter: StatisticsFilterValues): void => {
    this.createSummaryObjects(filter);
    this.filterChange.emit(filter);
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      campaigns: this.formBuilder.control(null),
      screens: this.formBuilder.control(null),
      targetGroups: this.formBuilder.control(null)
    });
    this.form.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(this.onFilterChange)
  }
}

interface StatisticsFilterFormGroup {
  campaigns: FormControl<Campaign[]>;
  screens: FormControl<Screen[]>;
  targetGroups: FormControl<TargetGroup[]>;
}

export interface StatisticsFilterValues {
  campaigns?: Campaign[];
  screens?: Screen[];
  targetGroups?: TargetGroup[];
}

interface FilterSummaryObject {
  id: number;
  label: string;
  filterType: 'campaigns' | 'screens' | 'targetGroups';
}
