<div class="grid grid-cols-3 gap-4">
  <div class="flex justify-start items-center">
    <mat-form-field class="mt-4">
      <mat-label>Gruppera visningar på</mat-label>
      <mat-select [formControl]="groupByFC">
        <mat-option [value]="GroupByData.Total">Totalt antal</mat-option>
        <mat-option [value]="GroupByData.Campaign">Kampanj</mat-option>
        <mat-option [value]="GroupByData.Screen">Skärm</mat-option>
        <mat-option [value]="GroupByData.TargetGroup">Målgrupp</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="flex justify-center items-center">
    <h2 class="chart-period m-0 text-center">
      {{columnType === 'date' ? startDT.monthLong : 'v. ' + startDT.weekNumber}}
    </h2>
  </div>

  <div class="flex justify-end items-center">
    <mat-checkbox class="mr-3"
                  [formControl]="hideDefaultAdFC">
      Dölj standardvisningar
    </mat-checkbox>
  </div>

</div>

<canvas class="chart-canvas"
        baseChart
        [data]="barChartData"
        [options]="barChartOptions"
        [plugins]="barChartPlugins"
        [type]="'bar'">
</canvas>
