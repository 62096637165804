import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TargetGroup } from 'src/_api';


@Component({
  selector: 'flow-target-group-create-modal',
  templateUrl: './target-group-create.modal.html',
  styleUrls: ['./target-group-create.modal.scss']
})
export class TargetGroupCreateModal implements OnInit {

  form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<TargetGroupCreateModal>,
    @Inject(MAT_DIALOG_DATA) public data: ModalData
  ) { }

  ngOnInit(): void {
    console.log(this.data);
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSaved(tg: TargetGroup): void {
    this.dialogRef.close(tg);
  }
}

interface ModalData {
  targetGroup?: TargetGroup;
}
