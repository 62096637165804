<h1 mat-dialog-title>Skapa ny målgrupp</h1>
<div mat-dialog-content
     class="py-2">
  <flow-target-group-create [isModal]="true"
                            (targetGroupSaved)="onSaved($event)"
                            (cancel)="onCancel()"></flow-target-group-create>
</div>
<!-- <div mat-dialog-actions>
  <button mat-button
          type="button"
          (click)="onCancel()">Avbryt</button>
  <button mat-raised-button
          type="button"
          (click)="onSave()"
          color="primary"
          cdkFocusInitial>Spara</button>
</div> -->
